import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { ProductContext } from "../../contexts/ProductContext";
import { toast } from "react-toastify";
import { MdArrowBackIos } from "react-icons/md";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill";

const EditProduct = () => {
  const { pkProdId } = useParams();
  const navigate = useNavigate();
  const { getProductDetails, fetchData } = useContext(ProductContext);

  const [product, setProduct] = useState({
    prodName: "",
    prodCode: "",
    prodPrice: "",
    quantity: 0,
    prodDescription: "",
    isActive: false,
    isB2B: false,
    isCustomize: false,
    isLockable: false,
    isZipper: false,
    images: [],
    imageColor: "",
    imgName: [],
    prodCategory: "",
    prodType: "",
    prodSize: "",
    prodMaterial: "",
  });

  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [materials, setMaterials] = useState([]);

  // Fetch product type
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/home/productTypeList`
        );
        console.log("Product Types Response:", response.data); // Debug log
        if (response.data && response.data.option) {
          setProductTypes(response.data.option);
        }
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchProductTypes();
  }, []);

  // // Fetch product categories
  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/prodCategory/search`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //           body: JSON.stringify({
  //             pkProdCategoryId: "",
  //             prodCategory: "",
  //             isActive: "",
  //             pageSize: 10,
  //             pageNo: 1,
  //           }),
  //         }
  //       );

  //       const data = await response.json();

  //       // Check if the response has the categories array
  //       if (data && data.categories) {
  //         setCategories(data.categories); // Update the state with fetched categories
  //       }
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   fetchCategories();
  // }, []);
  // Fetch product categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prodCategory/search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              pkProdCategoryId: "",
              prodCategory: "",
              isActive: "",
              isB2B: "",
              isCustomize: "",
              isLockable: "",
              isZipper: "",
              pageSize: 10,
              pageNo: 1,
            }),
          }
        );
        const data = await response.json();

        // Use 'data' key from the response to update categories
        if (data && data.data) {
          setCategories(data.data); // Update the state with the correct data
        } else {
          console.error("Categories not found in response");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // useEffect(() => {
  //   const fetchProductDetails = async () => {
  //     const product = await getProductDetails(pkProdId);
  //     if (product) {
  //       const info = product.info && product.info[0] ? product.info[0] : {};
  //       setProduct({
  //         prodName: product.prodName,
  //         prodPrice: product.prodPrice,
  //         quantity: info.quantity,
  //         prodDescription: product.prodDesc,
  //         isActive: product.isActive === "1",
  //         isCustomized: product.isCustomized === "1",
  //         images: info.imagePath ? info.imagePath.map((path) => path) : [],
  //         imageColor: info.imageColor || "#000000",
  //         imgName: info.imageName ? [info.imageName] : [],
  //         prodCategory: product.prodCategory || "",
  //         prodType: product.prodType || "",
  //       });
  //     }
  //   };

  //   fetchProductDetails();
  // }, [pkProdId, getProductDetails]);
  useEffect(() => {
    // Set static sizes and materials
    setSizes([
      { id: 1, title: "Small" },
      { id: 2, title: "Medium" },
      { id: 3, title: "Large" },
    ]);

    setMaterials([
      { id: 1, title: "Leather" },
      { id: 2, title: "Fabric" },
      { id: 3, title: "Glass" },
      { id: 4, title: "Velvet" },
    ]);

    const fetchProductDetails = async () => {
      const product = await getProductDetails(pkProdId);
      if (product) {
        const info = product.info && product.info[0] ? product.info[0] : {};
        setProduct((prevProduct) => ({
          ...prevProduct,
          prodName: product.prodName || "",
          prodCode: product.prodCode || "",
          prodPrice: product.prodPrice || "",
          quantity: info.quantity || 1, // Default to 1 instead of 0
          prodDescription: product.prodDesc || "",
          isActive: product.isActive === "1",
          isB2B: product.isB2B === "1",
          isCustomize: product.isCustomize === "1",
          isLockable: product.isLockable === "1",
          isZipper: product.isZipper === "1",
          images: info.imagePath ? info.imagePath.map((path) => path) : [],
          imageColor: info.imageColor || "#000000",
          imgName: info.imageName ? [info.imageName] : [],
          prodCategory: product.prodCategory || "",
          prodType: product.prodType || "",
          prodSize: product.prodSize || "", // Pre-fill product size
          prodMaterial: product.prodMaterial || "", // Pre-fill product material
        }));
      }
    };

    fetchProductDetails();
  }, [pkProdId, getProductDetails]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleFileChange = async (e) => {
  //   const files = Array.from(e.target.files);
  //   const compressedFiles = await Promise.all(
  //     files.map(async (file) => {
  //       const compressedFile = await imageCompression(file, {
  //         maxSizeMB: 1,
  //         maxWidthOrHeight: 1920,
  //       });
  //       return compressedFile;
  //     })
  //   );
  //   setProduct((prevProduct) => ({
  //     ...prevProduct,
  //     images: [...prevProduct.images, ...compressedFiles],
  //     imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
  //   }));
  // };
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });
        return compressedFile;
      })
    );

    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [...prevProduct.images, ...compressedFiles],
      imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
    }));
  };

  const handleRemoveImage = (index) => {
    setProduct((prevProduct) => {
      const newImages = [...prevProduct.images];
      newImages.splice(index, 1);
      const newImgNames = [...prevProduct.imgName];
      newImgNames.splice(index, 1);
      return { ...prevProduct, images: newImages, imgName: newImgNames };
    });
  };

  // // const handleSubmit = async (e) => {
  // //   e.preventDefault();

  // //   const formData = new FormData();
  // //   formData.append(
  // //     "data",
  // //     JSON.stringify({
  // //       prodName: product.prodName,
  // //       prodPrice: product.prodPrice.toString(),
  // //       quantity: product.quantity,
  // //       prodDescription: product.prodDescription,
  // //       isActive: product.isActive ? "1" : "0",
  // //       imageColor: product.imageColor,
  // //       imgName: product.imgName.join(","),
  // //       prodCategory: product.prodCategory,
  // //       prodType: product.prodType,
  // //       customize: product.customize,
  // //     })
  // //   );
  // //   product.images.forEach((image, index) => {
  // //     if (image instanceof Blob) {
  // //       formData.append(`files`, image, product.imgName[index]);
  // //     }
  // //   });

  // //   console.log("Submitting form data:", formData);

  // //   try {
  // //     const response = await axios.put(
  // //       `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
  // //       formData,
  // //       {
  // //         headers: {
  // //           "Content-Type": "multipart/form-data",
  // //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  // //         },
  // //       }
  // //     );
  // //     console.log("Response from API:", response.data);
  // //     toast.success("Updated!", {
  // //       autoClose: 1000,
  // //       position: "bottom-right",
  // //     });
  // //     navigate("/product");
  // //   } catch (error) {
  // //     console.error("Error updating product:", error);
  // //     const errorMessage =
  // //       error.response?.data?.message ||
  // //       "An error occurred while updating the product.";
  // //     toast.error(errorMessage, {
  // //       autoClose: 1000,
  // //       position: "bottom-right",
  // //     });
  // //   }
  // // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append(
  //     "data",
  //     JSON.stringify({
  //       prodName: product.prodName,
  //       prodPrice: product.prodPrice.toString(),
  //       quantity: product.quantity, // Ensure quantity is correct
  //       prodDescription: product.prodDescription,
  //       isActive: product.isActive ? "1" : "0",
  //       imageColor: product.imageColor,
  //       imgName: product.imgName.join(","),
  //       prodCategory: product.prodCategory,
  //       prodType: product.prodType,
  //       isCustomized: product.isCustomized ? "1" : "0", // Explicit customization flag
  //     })
  //   );

  //   product.images.forEach((image, index) => {
  //     if (image instanceof Blob) {
  //       formData.append(`files`, image, product.imgName[index]);
  //     }
  //   });

  //   try {
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     toast.success("Updated!", { autoClose: 1000, position: "bottom-right" });
  //     navigate("/product");
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message ||
  //       "An error occurred while updating the product.";
  //     toast.error(errorMessage, { autoClose: 1000, position: "bottom-right" });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure correct data format for formData
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        prodName: product.prodName,
        prodCode: product.prodCode,
        prodPrice: product.prodPrice.toString(), // Ensure price is string
        quantity: product.quantity || 1, // Default quantity to 1 if not set
        prodDescription: product.prodDescription,
        isActive: product.isActive ? "1" : "0",
        isB2B: product.isB2B ? "1" : "0",
        isLockable: product.isLockable ? "1" : "0",
        isZipper: product.isZipper ? "1" : "0",
        imageColor: product.imageColor,
        imgName: product.imgName.join(","),
        prodCategory: product.prodCategory,
        prodType: product.prodType,
        isCustomize: product.isCustomize ? "1" : "0", // Ensure this is sent properly
        prodSize: product.prodSize, // Include product size
        prodMaterial: product.prodMaterial, // Include product material
      })
    );

    // Add files if they are blobs
    product.images.forEach((image, index) => {
      if (image instanceof Blob) {
        formData.append(`files`, image, product.imgName[index]);
      }
    });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Show success message and navigate after successful update
      toast.success("Product updated successfully!", {
        autoClose: 1000,
        position: "bottom-right",
      });
      navigate("/product");

      fetchData();
    } catch (error) {
      // Handle errors more gracefully
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating the product.";
      toast.error(errorMessage, { autoClose: 1000, position: "bottom-right" });
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      prodDescription: value,
    }));
  };

  return (
    <section className="edit-product-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="edit-product-heading mb-4">
            <h2>Edit Product</h2>
          </div>
          <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
            <button
              onClick={handleBackClick}
              className="back-btn-per-page mb-5"
            >
              <MdArrowBackIos className="back-icon-per-page" />
              Back
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-12">
                <label htmlFor="prodName" className="form-label fw-medium">
                  Product Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="prodName"
                  placeholder="Product Name"
                  value={product.prodName}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodCode" className="form-label fw-medium">
                  Product Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="prodCode"
                  placeholder="Product Name"
                  value={product.prodCode}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodPrice" className="form-label fw-medium">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="prodPrice"
                  placeholder="Price"
                  value={product.prodPrice}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="quantity" className="form-label fw-medium">
                  Quantity
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  placeholder="Quantity"
                  value={product.quantity}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              {/* <div className="col-12">
                <label htmlFor="prodDescription" className="form-label fw-medium">
                  Product Description
                </label>
                <textarea
                  className="form-control"
                  id="prodDescription"
                  rows="3"
                  placeholder="Product Description"
                  value={product.prodDescription}
                  onChange={handleChange}
                  autoComplete="off"
                ></textarea>
              </div> */}
              <div className="col-12">
                <label
                  htmlFor="prodDescription"
                  className="form-label fw-medium"
                >
                  Product Description
                </label>
                <ReactQuill
                  value={product.prodDescription}
                  onChange={handleDescriptionChange}
                  id="prodDescription"
                  placeholder="Product Description"
                  autoComplete="off"
                />
              </div>
              <div className="col-2">
                <label htmlFor="imageColor" className="form-label fw-medium">
                  Image Color
                </label>
                <input
                  type="color"
                  className="form-control"
                  id="imageColor"
                  placeholder="Image Color"
                  value={product.imageColor}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-4 mb-3">
                    <label htmlFor="imagePath" className="form-label fw-medium">
                      Images
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imagePath"
                      title="Choose your images"
                      multiple
                      onChange={handleFileChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 only-images-name">
                    {product.images.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={
                            image instanceof Blob
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Uploaded ${index}`}
                        />
                        <RxCross2
                          className="img-cross-icon"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isActive" className="form-label fw-medium">
                  Active
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isActive"
                  checked={product.isActive}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isB2B" className="form-label fw-medium">
                  B2B
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isB2B"
                  checked={product.isB2B}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isCustomize" className="form-label fw-medium">
                  Customize
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isCustomize"
                  checked={product.isCustomize}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isLockable" className="form-label fw-medium">
                  isLockable
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isLockable"
                  checked={product.isLockable}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isZipper" className="form-label fw-medium">
                  isZipper
                </label>
                <input
                  type="checkbox"
                  className="form-check"
                  id="isZipper"
                  checked={product.isZipper}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label htmlFor="prodCategory" className="form-label">
                  Category
                </label>
                <select
                  id="prodCategory"
                  className="form-select"
                  value={product.prodCategory}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option
                      key={category.pkProdCategoryId}
                      value={category.productCategory}
                    >
                      {category.productCategory}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 d-flex flex-column">
                <label htmlFor="prodSize" className="form-label fw-medium m-0">
                  Product Size
                </label>
                <select
                  className="form-select"
                  id="prodSize"
                  value={product.prodSize}
                  onChange={handleChange}
                >
                  <option value="">Select Product Size</option>
                  {sizes.map((size) => (
                    <option key={size.id} value={size.title}>
                      {size.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 d-flex flex-column">
                <label
                  htmlFor="prodMaterial"
                  className="form-label fw-medium m-0"
                >
                  Product Material
                </label>
                <select
                  className="form-select"
                  id="prodMaterial"
                  value={product.prodMaterial} // bind to state
                  onChange={handleChange} // update on change
                >
                  <option value="">Select Product Material</option>
                  {materials.map((material) => (
                    <option key={material.id} value={material.title}>
                      {material.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12">
                <label htmlFor="prodType" className="form-label fw-medium">
                  Product Type
                </label>
                <select
                  className="form-select"
                  id="prodType"
                  value={product.prodType}
                  onChange={handleChange}
                >
                  <option value="">Select Product Type</option>
                  {productTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProduct;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import imageCompression from "browser-image-compression";
// import { useNavigate, useParams } from "react-router-dom";
// import { RxCross2 } from "react-icons/rx";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";
// import { MdArrowBackIos } from "react-icons/md";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import ReactQuill from "react-quill";

// const EditProduct = () => {
//   const { pkProdId } = useParams();
//   const navigate = useNavigate();
//   const { getProductDetails } = useContext(ProductContext);

//   const [product, setProduct] = useState({
//     prodName: "",
//     prodPrice: "",
//     quantity: 0,
//     prodDescription: "",
//     isActive: false,
//     isCustomized: false,
//     images: [],
//     imageColor: "#000000",
//     imgName: [],
//     prodCategory: "",
//     prodType: "",
//   });

//   const [categories, setCategories] = useState([]);
//   const [productTypes, setProductTypes] = useState([]);

//   // Fetch product type
//   useEffect(() => {
//     const fetchProductTypes = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/home/productTypeList`
//         );
//         if (response.data && response.data.option) {
//           setProductTypes(response.data.option);
//         }
//       } catch (error) {
//         console.error("Error fetching product types:", error);
//       }
//     };

//     fetchProductTypes();
//   }, []);

//   // Fetch product categories
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/prodCategory/search`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//             body: JSON.stringify({
//               pkProdCategoryId: "",
//               prodCategory: "",
//               isActive: "",
//               pageSize: 10,
//               pageNo: 1,
//             }),
//           }
//         );

//         const data = await response.json();

//         // Check if the response has the categories array
//         if (data && data.categories) {
//           setCategories(data.categories); // Update the state with fetched categories
//         }
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   // useEffect(() => {
//   //   const fetchProductDetails = async () => {
//   //     const product = await getProductDetails(pkProdId);
//   //     if (product) {
//   //       const info = product.info && product.info[0] ? product.info[0] : {};
//   //       setProduct({
//   //         prodName: product.prodName,
//   //         prodPrice: product.prodPrice,
//   //         quantity: info.quantity,
//   //         prodDescription: product.prodDesc,
//   //         isActive: product.isActive === "1",
//   //         isCustomized: product.isCustomized === "1",
//   //         images: info.imagePath ? info.imagePath.map((path) => path) : [],
//   //         imageColor: info.imageColor || "#000000",
//   //         imgName: info.imageName ? [info.imageName] : [],
//   //         prodCategory: product.prodCategory || "",
//   //         prodType: product.prodType || "",
//   //       });
//   //     }
//   //   };

//   //   fetchProductDetails();
//   // }, [pkProdId, getProductDetails]);
//   useEffect(() => {
//     const fetchProductDetails = async () => {
//       const product = await getProductDetails(pkProdId);
//       if (product) {
//         const info = product.info && product.info[0] ? product.info[0] : {};
//         setProduct((prevProduct) => ({
//           ...prevProduct,
//           prodName: product.prodName || "",
//           prodPrice: product.prodPrice || "",
//           quantity: info.quantity || 1, // Default to 1 instead of 0
//           prodDescription: product.prodDesc || "",
//           isActive: product.isActive === "1",
//           isCustomized: product.isCustomized === "1",
//           images: info.imagePath ? info.imagePath.map((path) => path) : [],
//           imageColor: info.imageColor || "#000000",
//           imgName: info.imageName ? [info.imageName] : [],
//           prodCategory: product.prodCategory || "",
//           prodType: product.prodType || "",
//         }));
//       }
//     };

//     fetchProductDetails();
//   }, [pkProdId, getProductDetails]);

//   const handleChange = (e) => {
//     const { id, value, type, checked } = e.target;
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       [id]: type === "checkbox" ? checked : value,
//     }));
//   };

//   // const handleFileChange = async (e) => {
//   //   const files = Array.from(e.target.files);
//   //   const compressedFiles = await Promise.all(
//   //     files.map(async (file) => {
//   //       const compressedFile = await imageCompression(file, {
//   //         maxSizeMB: 1,
//   //         maxWidthOrHeight: 1920,
//   //       });
//   //       return compressedFile;
//   //     })
//   //   );
//   //   setProduct((prevProduct) => ({
//   //     ...prevProduct,
//   //     images: [...prevProduct.images, ...compressedFiles],
//   //     imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//   //   }));
//   // };
//   const handleFileChange = async (e) => {
//     const files = Array.from(e.target.files);
//     const compressedFiles = await Promise.all(
//       files.map(async (file) => {
//         const compressedFile = await imageCompression(file, {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//         });
//         return compressedFile;
//       })
//     );

//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       images: [...prevProduct.images, ...compressedFiles],
//       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//     }));
//   };

//   const handleRemoveImage = (index) => {
//     setProduct((prevProduct) => {
//       const newImages = [...prevProduct.images];
//       newImages.splice(index, 1);
//       const newImgNames = [...prevProduct.imgName];
//       newImgNames.splice(index, 1);
//       return { ...prevProduct, images: newImages, imgName: newImgNames };
//     });
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();

//   //   const formData = new FormData();
//   //   formData.append(
//   //     "data",
//   //     JSON.stringify({
//   //       prodName: product.prodName,
//   //       prodPrice: product.prodPrice.toString(),
//   //       quantity: product.quantity,
//   //       prodDescription: product.prodDescription,
//   //       isActive: product.isActive ? "1" : "0",
//   //       imageColor: product.imageColor,
//   //       imgName: product.imgName.join(","),
//   //       prodCategory: product.prodCategory,
//   //       prodType: product.prodType,
//   //       customize: product.customize,
//   //     })
//   //   );
//   //   product.images.forEach((image, index) => {
//   //     if (image instanceof Blob) {
//   //       formData.append(`files`, image, product.imgName[index]);
//   //     }
//   //   });

//   //   console.log("Submitting form data:", formData);

//   //   try {
//   //     const response = await axios.put(
//   //       `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//   //       formData,
//   //       {
//   //         headers: {
//   //           "Content-Type": "multipart/form-data",
//   //           Authorization: `Bearer ${localStorage.getItem("token")}`,
//   //         },
//   //       }
//   //     );
//   //     console.log("Response from API:", response.data);
//   //     toast.success("Updated!", {
//   //       autoClose: 1000,
//   //       position: "bottom-right",
//   //     });
//   //     navigate("/product");
//   //   } catch (error) {
//   //     console.error("Error updating product:", error);
//   //     const errorMessage =
//   //       error.response?.data?.message ||
//   //       "An error occurred while updating the product.";
//   //     toast.error(errorMessage, {
//   //       autoClose: 1000,
//   //       position: "bottom-right",
//   //     });
//   //   }
//   // };
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append(
//       "data",
//       JSON.stringify({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice.toString(),
//         quantity: product.quantity,  // Ensure quantity is correct
//         prodDescription: product.prodDescription,
//         isActive: product.isActive ? "1" : "0",
//         imageColor: product.imageColor,
//         imgName: product.imgName.join(","),
//         prodCategory: product.prodCategory,
//         prodType: product.prodType,
//         isCustomized: product.isCustomized ? "1" : "0", // Explicit customization flag
//       })
//     );

//     product.images.forEach((image, index) => {
//       if (image instanceof Blob) {
//         formData.append(`files`, image, product.imgName[index]);
//       }
//     });

//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       toast.success("Updated!", { autoClose: 1000, position: "bottom-right" });
//       navigate("/product");
//     } catch (error) {
//       const errorMessage =
//         error.response?.data?.message || "An error occurred while updating the product.";
//       toast.error(errorMessage, { autoClose: 1000, position: "bottom-right" });
//     }
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   const handleDescriptionChange = (value) => {
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       prodDescription: value,
//     }));
//   };

//   return (
//     <section className="edit-product-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="edit-product-heading mb-4">
//             <h2>Edit Product</h2>
//           </div>
//           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
//             <button
//               onClick={handleBackClick}
//               className="back-btn-per-page mb-5"
//             >
//               <MdArrowBackIos className="back-icon-per-page" />
//               Back
//             </button>
//             <form className="row g-3" onSubmit={handleSubmit}>
//               <div className="col-12">
//                 <label htmlFor="prodName" className="form-label fw-medium">
//                   Product Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="prodName"
//                   placeholder="Product Name"
//                   value={product.prodName}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodPrice" className="form-label fw-medium">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="prodPrice"
//                   placeholder="Price"
//                   value={product.prodPrice}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="quantity" className="form-label fw-medium">
//                   Quantity
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="quantity"
//                   placeholder="Quantity"
//                   value={product.quantity}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               {/* <div className="col-12">
//                 <label htmlFor="prodDescription" className="form-label fw-medium">
//                   Product Description
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="prodDescription"
//                   rows="3"
//                   placeholder="Product Description"
//                   value={product.prodDescription}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 ></textarea>
//               </div> */}
//               <div className="col-12">
//                 <label
//                   htmlFor="prodDescription"
//                   className="form-label fw-medium"
//                 >
//                   Product Description
//                 </label>
//                 <ReactQuill
//                   value={product.prodDescription}
//                   onChange={handleDescriptionChange}
//                   id="prodDescription"
//                   placeholder="Product Description"
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-2">
//                 <label htmlFor="imageColor" className="form-label fw-medium">
//                   Image Color
//                 </label>
//                 <input
//                   type="color"
//                   className="form-control"
//                   id="imageColor"
//                   placeholder="Image Color"
//                   value={product.imageColor}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 mb-3">
//                 <div className="row">
//                   <div className="col-4 mb-3">
//                     <label htmlFor="imagePath" className="form-label fw-medium">
//                       Images
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="imagePath"
//                       title="Choose your images"
//                       multiple
//                       onChange={handleFileChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="col-12 only-images-name">
//                     {product.images.map((image, index) => (
//                       <div key={index} className="uploaded-image">
//                         <img
//                           src={
//                             image instanceof Blob
//                               ? URL.createObjectURL(image)
//                               : image
//                           }
//                           alt={`Uploaded ${index}`}
//                         />
//                         <RxCross2
//                           className="img-cross-icon"
//                           onClick={() => handleRemoveImage(index)}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isActive" className="form-label fw-medium">
//                   Active
//                 </label>
//                 <input
//                   type="checkbox"
//                   className="form-check"
//                   id="isActive"
//                   checked={product.isActive}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isCustomized" className="form-label fw-medium">
//                   Customize
//                 </label>
//                 <input
//                   type="checkbox"
//                   className="form-check"
//                   id="isCustomized"
//                   checked={product.isCustomized}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodCategory" className="form-label fw-medium">
//                   Product Category
//                 </label>
//                 <select
//                   className="form-control"
//                   id="prodCategory"
//                   value={product.prodCategory}
//                   onChange={handleChange}
//                 >
//                   <option value="" disabled>
//                     Select Product Category
//                   </option>
//                   {categories.map((category) => (
//                     <option key={category.pkCatId} value={category.pkCatId}>
//                       {category.catName}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodType" className="form-label fw-medium">
//                   Product Type
//                 </label>
//                 <select
//                   className="form-control"
//                   id="prodType"
//                   value={product.prodType}
//                   onChange={handleChange}
//                 >
//                   <option value="" disabled>Select Product Type</option>
//                   {productTypes.map((type, index) => (
//                     <option key={index} value={type}>
//                       {type}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="col-12 mt-4">
//                 <button
//                   type="submit"
//                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
//                 >
//                   Update
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EditProduct;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import imageCompression from "browser-image-compression";
// import { useNavigate, useParams } from "react-router-dom";
// import { RxCross2 } from "react-icons/rx";
// import { ProductContext } from "../../contexts/ProductContext";
// import { toast } from "react-toastify";
// import { MdArrowBackIos } from "react-icons/md";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import ReactQuill from "react-quill";

// const EditProduct = () => {
//   const { pkProdId } = useParams();
//   const navigate = useNavigate();
//   const { getProductDetails } = useContext(ProductContext);

//   const [product, setProduct] = useState({
//     prodName: "",
//     prodPrice: "",
//     quantity: 0,
//     prodDescription: "",
//     isActive: false,
//     isCustomized: false,
//     images: [],
//     imageColor: "#000000",
//     imgName: [],
//     prodCategory: "",
//     prodType: "",
//   });

//   const [productTypes, setProductTypes] = useState([]);

//   useEffect(() => {
//     const fetchProductTypes = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/home/productTypeList`
//         );
//         console.log("Product Types Response:", response.data); // Debug log
//         if (response.data && response.data.option) {
//           setProductTypes(response.data.option);
//         }
//       } catch (error) {
//         console.error("Error fetching product types:", error);
//       }
//     };

//     fetchProductTypes();
//   }, []);

//   useEffect(() => {
//     const fetchProductDetails = async () => {
//       const product = await getProductDetails(pkProdId);
//       if (product) {
//         const info = product.info && product.info[0] ? product.info[0] : {};
//         setProduct({
//           prodName: product.prodName,
//           prodPrice: product.prodPrice,
//           quantity: info.quantity,
//           prodDescription: product.prodDesc,
//           isActive: product.isActive === "1",
//           isCustomized: product.isCustomized === "1",
//           images: info.imagePath ? info.imagePath.map((path) => path) : [],
//           imageColor: info.imageColor || "#000000",
//           imgName: info.imageName ? [info.imageName] : [],
//           prodCategory: product.prodCategory || "",
//           prodType: product.prodType || "",
//         });
//       }
//     };

//     fetchProductDetails();
//   }, [pkProdId, getProductDetails]);

//   const handleChange = (e) => {
//     const { id, value, type, checked } = e.target;
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       [id]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleFileChange = async (e) => {
//     const files = Array.from(e.target.files);
//     const compressedFiles = await Promise.all(
//       files.map(async (file) => {
//         const compressedFile = await imageCompression(file, {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//         });
//         return compressedFile;
//       })
//     );
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       images: [...prevProduct.images, ...compressedFiles],
//       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//     }));
//   };

//   const handleRemoveImage = (index) => {
//     setProduct((prevProduct) => {
//       const newImages = [...prevProduct.images];
//       newImages.splice(index, 1);
//       const newImgNames = [...prevProduct.imgName];
//       newImgNames.splice(index, 1);
//       return { ...prevProduct, images: newImages, imgName: newImgNames };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append(
//       "data",
//       JSON.stringify({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice.toString(),
//         quantity: product.quantity,
//         prodDescription: product.prodDescription,
//         isActive: product.isActive ? "1" : "0",
//         imageColor: product.imageColor,
//         imgName: product.imgName.join(","),
//         prodCategory: product.prodCategory,
//         prodType: product.prodType,
//         customize: product.customize,
//       })
//     );
//     product.images.forEach((image, index) => {
//       if (image instanceof Blob) {
//         formData.append(`files`, image, product.imgName[index]);
//       }
//     });

//     console.log("Submitting form data:", formData);

//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       console.log("Response from API:", response.data);
//       toast.success("Updated!", {
//         autoClose: 1000,
//         position: "bottom-right",
//       });
//       navigate("/product");
//     } catch (error) {
//       console.error("Error updating product:", error);
//       const errorMessage =
//         error.response?.data?.message ||
//         "An error occurred while updating the product.";
//       toast.error(errorMessage, {
//         autoClose: 1000,
//         position: "bottom-right",
//       });
//     }
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   const handleDescriptionChange = (value) => {
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       prodDescription: value,
//     }));
//   };

//   return (
//     <section className="edit-product-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="edit-product-heading mb-4">
//             <h2>Edit Product</h2>
//           </div>
//           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
//             <button
//               onClick={handleBackClick}
//               className="back-btn-per-page mb-5"
//             >
//               <MdArrowBackIos className="back-icon-per-page" />
//               Back
//             </button>
//             <form className="row g-3" onSubmit={handleSubmit}>
//               <div className="col-12">
//                 <label htmlFor="prodName" className="form-label fw-medium">
//                   Product Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="prodName"
//                   placeholder="Product Name"
//                   value={product.prodName}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodPrice" className="form-label fw-medium">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="prodPrice"
//                   placeholder="Price"
//                   value={product.prodPrice}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="quantity" className="form-label fw-medium">
//                   Quantity
//                 </label>
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="quantity"
//                   placeholder="Quantity"
//                   value={product.quantity}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               {/* <div className="col-12">
//                 <label htmlFor="prodDescription" className="form-label fw-medium">
//                   Product Description
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="prodDescription"
//                   rows="3"
//                   placeholder="Product Description"
//                   value={product.prodDescription}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 ></textarea>
//               </div> */}
//               <div className="col-12">
//                 <label
//                   htmlFor="prodDescription"
//                   className="form-label fw-medium"
//                 >
//                   Product Description
//                 </label>
//                 <ReactQuill
//                   value={product.prodDescription}
//                   onChange={handleDescriptionChange}
//                   id="prodDescription"
//                   placeholder="Product Description"
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-2">
//                 <label htmlFor="imageColor" className="form-label fw-medium">
//                   Image Color
//                 </label>
//                 <input
//                   type="color"
//                   className="form-control"
//                   id="imageColor"
//                   placeholder="Image Color"
//                   value={product.imageColor}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 mb-3">
//                 <div className="row">
//                   <div className="col-4 mb-3">
//                     <label htmlFor="imagePath" className="form-label fw-medium">
//                       Images
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="imagePath"
//                       title="Choose your images"
//                       multiple
//                       onChange={handleFileChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="col-12 only-images-name">
//                     {product.images.map((image, index) => (
//                       <div key={index} className="uploaded-image">
//                         <img
//                           src={
//                             image instanceof Blob
//                               ? URL.createObjectURL(image)
//                               : image
//                           }
//                           alt={`Uploaded ${index}`}
//                         />
//                         <RxCross2
//                           className="img-cross-icon"
//                           onClick={() => handleRemoveImage(index)}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isActive" className="form-label fw-medium">
//                   Active
//                 </label>
//                 <input
//                   type="checkbox"
//                   className="form-check"
//                   id="isActive"
//                   checked={product.isActive}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isCustomized" className="form-label fw-medium">
//                   Customize
//                 </label>
//                 <input
//                   type="checkbox"
//                   className="form-check"
//                   id="isCustomized"
//                   checked={product.isCustomized}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodCategory" className="form-label fw-medium">
//                   Product Category
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="prodCategory"
//                   placeholder="Product Category"
//                   value={product.prodCategory}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodType" className="form-label fw-medium">
//                   Product Type
//                 </label>
//                 <select
//                   className="form-control"
//                   id="prodType"
//                   value={product.prodType}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Product Type</option>
//                   {productTypes.map((type, index) => (
//                     <option key={index} value={type}>
//                       {type}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="col-12 mt-4">
//                 <button
//                   type="submit"
//                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
//                 >
//                   Update
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EditProduct;

// // import React, { useState, useEffect, useContext } from "react";
// // import axios from "axios";
// // import imageCompression from "browser-image-compression";
// // import { useNavigate, useParams } from "react-router-dom";
// // import { RxCross2 } from "react-icons/rx";
// // import { ProductContext } from "../../contexts/ProductContext";
// // import { toast } from "react-toastify";
// // import { MdArrowBackIos } from "react-icons/md";

// // const EditProduct = () => {
// //   const { pkProdId } = useParams();
// //   const navigate = useNavigate();
// //   const { getProductDetails } = useContext(ProductContext);

// //   const [product, setProduct] = useState({
// //     prodName: "",
// //     prodPrice: "",
// //     quantity: 0,
// //     prodDescription: "",
// //     customMessage: "",
// //     zodiacSign: "",
// //     isActive: false,
// //     images: [],
// //     imageColor: "#000000",
// //     imgName: [],
// //     prodCategory: "",
// //   });

// //   useEffect(() => {
// //     const fetchProductDetails = async () => {
// //       const product = await getProductDetails(pkProdId);
// //       if (product) {
// //         const info = product.info && product.info[0] ? product.info[0] : {};
// //         setProduct({
// //           prodName: product.prodName,
// //           prodPrice: product.prodPrice,
// //           quantity: info.quantity,
// //           prodDescription: product.prodDesc,
// //           customMessage: info.customMessage || "",
// //           zodiacSign: info.zodiacSign || "",
// //           isActive: product.isActive === "1",
// //           images: info.imagePath ? info.imagePath.map((path) => path) : [],
// //           imageColor: info.imageColor || "#000000",
// //           imgName: info.imageName ? [info.imageName] : [],
// //           prodCategory: product.prodCategory || "",
// //         });
// //       }
// //     };

// //     fetchProductDetails();
// //   }, [pkProdId, getProductDetails]);

// //   const handleChange = (e) => {
// //     const { id, value, type, checked } = e.target;
// //     setProduct((prevProduct) => ({
// //       ...prevProduct,
// //       [id]: type === "checkbox" ? checked : value,
// //     }));
// //   };

// //   const handleFileChange = async (e) => {
// //     const files = Array.from(e.target.files);
// //     const compressedFiles = await Promise.all(
// //       files.map(async (file) => {
// //         const compressedFile = await imageCompression(file, {
// //           maxSizeMB: 1,
// //           maxWidthOrHeight: 1920,
// //         });
// //         return compressedFile;
// //       })
// //     );
// //     setProduct((prevProduct) => ({
// //       ...prevProduct,
// //       images: [...prevProduct.images, ...compressedFiles],
// //       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
// //     }));
// //   };

// //   const handleRemoveImage = (index) => {
// //     setProduct((prevProduct) => {
// //       const newImages = [...prevProduct.images];
// //       newImages.splice(index, 1);
// //       const newImgNames = [...prevProduct.imgName];
// //       newImgNames.splice(index, 1);
// //       return { ...prevProduct, images: newImages, imgName: newImgNames };
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     // Create FormData instance
// //     const formData = new FormData();

// //     // Append data as JSON string
// //     formData.append(
// //       "data",
// //       JSON.stringify({
// //         prodName: product.prodName,
// //         prodPrice: product.prodPrice.toString(),
// //         quantity: product.quantity,
// //         prodDescription: product.prodDescription,
// //         customMessage: product.customMessage,
// //         zodiacSign: product.zodiacSign,
// //         isActive: product.isActive ? "1" : "0",
// //         imageColor: product.imageColor,
// //         imgName: product.imgName.join(","),
// //         prodCategory: product.prodCategory,
// //       })
// //     );

// //     // Append files
// //     product.images.forEach((image, index) => {
// //       if (image instanceof Blob) {
// //         formData.append(`files`, image, product.imgName[index]);
// //       }
// //     });

// //     console.log("Submitting form data:", formData); // Debug log

// //     try {
// //       const response = await axios.put(
// //         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// //           },
// //         }
// //       );
// //       console.log("Response from API:", response.data); // Debug log
// //       toast.success("Updated!");
// //       navigate("/product");
// //     } catch (error) {
// //       console.error("Error updating product:", error); // Debug log
// //       const errorMessage =
// //         error.response?.data?.message ||
// //         "An error occurred while updating the product.";
// //       toast.error(errorMessage);
// //     }
// //   };

// //   // const handleSubmit = async (e) => {
// //   //   e.preventDefault();

// //   //   const formData = new FormData();
// //   //   formData.append(
// //   //     "data",
// //   //     JSON.stringify({
// //   //       prodName: product.prodName,
// //   //       prodPrice: product.prodPrice.toString(),
// //   //       quantity: product.quantity,
// //   //       prodDescription: product.prodDescription,
// //   //       customMessage: product.customMessage,
// //   //       zodiacSign: product.zodiacSign,
// //   //       isActive: product.isActive ? "1" : "0",
// //   //       imageColor: product.imageColor,
// //   //       imgName: product.imgName.join(","),
// //   //       prodCategory: product.prodCategory,
// //   //     })
// //   //   );

// //   //   product.images.forEach((image, index) => {
// //   //     if (image instanceof Blob) {
// //   //       formData.append(`files`, image, product.imgName[index]);
// //   //     }
// //   //   });

// //   //   try {
// //   //     await axios.put(
// //   //       `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
// //   //       formData,
// //   //       {
// //   //         headers: {
// //   //           "Content-Type": "multipart/form-data",
// //   //           Authorization: `Bearer ${localStorage.getItem("token")}`,
// //   //         },
// //   //       }
// //   //     );
// //   //     toast.success("Updated!");
// //   //     navigate("/product");
// //   //   } catch (error) {
// //   //     const errorMessage =
// //   //       error.response?.data?.message ||
// //   //       "An error occurred while updating the product.";
// //   //     toast.error(errorMessage);
// //   //   }
// //   // };

// //   const handleBackClick = () => {
// //     navigate(-1);
// //   };

// //   return (
// //     <section className="edit-product-page">
// //       <div className="our-container px-3">
// //         <div className="inner-container">
// //           <div className="edit-product-heading mb-4">
// //             <h2>Edit Product</h2>
// //           </div>
// //           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
// //             <button
// //               onClick={handleBackClick}
// //               className="back-btn-per-page mb-5"
// //             >
// //               <MdArrowBackIos className="back-icon-per-page" />
// //               Back
// //             </button>
// //             <form className="row g-3" onSubmit={handleSubmit}>
// //               <div className="col-12">
// //                 <label htmlFor="prodName" className="form-label fw-medium">
// //                   Product Name
// //                 </label>
// //                 <input
// //                   type="text"
// //                   className="form-control"
// //                   id="prodName"
// //                   placeholder="Product Name"
// //                   value={product.prodName}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="prodPrice" className="form-label fw-medium">
// //                   Price
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="prodPrice"
// //                   placeholder="Price"
// //                   value={product.prodPrice}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="quantity" className="form-label fw-medium">
// //                   Quantity
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="quantity"
// //                   placeholder="Quantity"
// //                   value={product.quantity}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label
// //                   htmlFor="prodDescription"
// //                   className="form-label fw-medium"
// //                 >
// //                   Product Description
// //                 </label>
// //                 <textarea
// //                   className="form-control"
// //                   id="prodDescription"
// //                   rows="3"
// //                   placeholder="Product Description"
// //                   value={product.prodDescription}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 ></textarea>
// //               </div>
// //               <div className="col-2">
// //                 <label htmlFor="imageColor" className="form-label fw-medium">
// //                   Image Color
// //                 </label>
// //                 <input
// //                   type="color"
// //                   className="form-control"
// //                   id="imageColor"
// //                   placeholder="Image Color"
// //                   value={product.imageColor}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12 mb-3">
// //                 <div className="row">
// //                   <div className="col-4 mb-3">
// //                     <label htmlFor="imagePath" className="form-label fw-medium">
// //                       Images
// //                     </label>
// //                     <input
// //                       type="file"
// //                       className="form-control"
// //                       id="imagePath"
// //                       title="Choose your images"
// //                       multiple
// //                       onChange={handleFileChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="col-12 only-images-name">
// //                     {product.images.map((image, index) => (
// //                       <div key={index} className="uploaded-image">
// //                         <img
// //                           src={
// //                             image instanceof Blob
// //                               ? URL.createObjectURL(image)
// //                               : image
// //                           }
// //                           alt={`Uploaded ${index}`}
// //                         />
// //                         <RxCross2
// //                           onClick={() => handleRemoveImage(index)}
// //                           className="img-cross-icon"
// //                         />
// //                       </div>
// //                     ))}
// //                   </div>
// //                 </div>
// //               </div>
// //               <div className="col-12 d-flex gap-2">
// //                 <label htmlFor="isActive" className="form-label fw-medium">
// //                   Active
// //                 </label>
// //                 <span>
// //                   <input
// //                     type="checkbox"
// //                     id="isActive"
// //                     className="checkbox"
// //                     checked={product.isActive}
// //                     onChange={handleChange}
// //                   />
// //                   <label htmlFor="isActive" className="toggle"></label>
// //                 </span>
// //               </div>
// //               <div className="col-12 d-flex flex-column">
// //                 <label
// //                   htmlFor="prodCategory"
// //                   className="form-label fw-medium m-0"
// //                 >
// //                   Product Category
// //                 </label>
// //                 <select
// //                   className="form-select"
// //                   id="prodCategory"
// //                   value={product.prodCategory}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Category</option>
// //                   <option value="Customized">Customized</option>
// //                   <option value="Non-Customized">Non-Customized</option>
// //                 </select>
// //               </div>
// //               <div className="col-12 mt-5">
// //                 <button
// //                   type="submit"
// //                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
// //                 >
// //                   Update Product
// //                 </button>
// //               </div>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default EditProduct;

// // import React, { useState, useEffect, useContext } from "react";
// // import axios from "axios";
// // import imageCompression from "browser-image-compression";
// // import { useNavigate, useParams } from "react-router-dom";
// // import { RxCross2 } from "react-icons/rx";
// // import { ProductContext } from "../../contexts/ProductContext";
// // import { toast } from "react-toastify";
// // import { MdArrowBackIos } from "react-icons/md";

// // const EditProduct = () => {
// //   const { pkProdId } = useParams();
// //   const navigate = useNavigate();
// //   const { getProductDetails } = useContext(ProductContext);

// //   const [product, setProduct] = useState({
// //     prodName: "",
// //     prodPrice: "",
// //     quantity: 0,
// //     prodDescription: "",
// //     customMessage: "",
// //     zodiacSign: "",
// //     isActive: false,
// //     images: [],
// //     imageColor: "#000000",
// //     imgName: [],
// //     prodCategory: "",
// //   });

// //   useEffect(() => {
// //     const product = getProductDetails(pkProdId);
// //     if (product) {
// //       const info = product.info && product.info[0] ? product.info[0] : {};
// //       setProduct({
// //         prodName: product.prodName,
// //         prodPrice: product.prodPrice,
// //         quantity: info.quantity,
// //         prodDescription: product.prodDesc,
// //         customMessage: "",
// //         zodiacSign: "",
// //         isActive: product.isActive === "1",
// //         images: info.imagePath ? info.imagePath.map((path) => path) : [],
// //         imageColor: info.imageColor || "#000000",
// //         imgName: info.imageName ? [info.imageName] : [],
// //         prodCategory: product.prodCategory || "",
// //       });
// //     }
// //   }, [pkProdId, getProductDetails]);

// //   const handleChange = (e) => {
// //     const { id, value, type, checked } = e.target;
// //     setProduct((prevProduct) => ({
// //       ...prevProduct,
// //       [id]: type === "checkbox" ? checked : value,
// //     }));
// //   };

// //   const handleFileChange = async (e) => {
// //     const files = Array.from(e.target.files);
// //     const compressedFiles = await Promise.all(
// //       files.map(async (file) => {
// //         const compressedFile = await imageCompression(file, {
// //           maxSizeMB: 1,
// //           maxWidthOrHeight: 1920,
// //         });
// //         return compressedFile;
// //       })
// //     );
// //     setProduct((prevProduct) => ({
// //       ...prevProduct,
// //       images: [...prevProduct.images, ...compressedFiles],
// //       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
// //     }));
// //   };

// //   const handleRemoveImage = (index) => {
// //     setProduct((prevProduct) => {
// //       const newImages = [...prevProduct.images];
// //       newImages.splice(index, 1);
// //       const newImgNames = [...prevProduct.imgName];
// //       newImgNames.splice(index, 1);
// //       return { ...prevProduct, images: newImages, imgName: newImgNames };
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     const formData = new FormData();
// //     formData.append(
// //       "data",
// //       JSON.stringify({
// //         prodName: product.prodName,
// //         prodPrice: product.prodPrice.toString(),
// //         quantity: product.quantity,
// //         prodDescription: product.prodDescription,
// //         customMessage: product.customMessage,
// //         zodiacSign: product.zodiacSign,
// //         isActive: product.isActive ? "1" : "0",
// //         imageColor: product.imageColor,
// //         imgName: product.imgName.join(","),
// //         prodCategory: product.prodCategory,
// //       })
// //     );

// //     product.images.forEach((image, index) => {
// //       if (image instanceof Blob) {
// //         formData.append(`files`, image, product.imgName[index]);
// //       }
// //     });

// //     try {
// //       await axios.put(
// //         `${process.env.REACT_APP_API_URL}/product/update/${pkProdId}`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// //           },
// //         }
// //       );
// //       toast.success("Updated!");
// //       navigate("/product");
// //     } catch (error) {
// //       const errorMessage =
// //         error.response?.data?.message ||
// //         "An error occurred while updating the product.";
// //       toast.error(errorMessage);
// //     }
// //   };

// //   const handleBackClick = () => {
// //     navigate(-1);
// //   };

// //   return (
// //     <section className="edit-product-page">
// //       <div className="our-container px-3">
// //         <div className="inner-container">
// //           <div className="edit-product-heading mb-4">
// //             <h2>Edit Product</h2>
// //           </div>
// //           <div className="edit-product-body col-md-8 col-lg-8 col-12 mx-auto">
// //             <button
// //               onClick={handleBackClick}
// //               className="back-btn-per-page mb-5"
// //             >
// //               <MdArrowBackIos className="back-icon-per-page" />
// //               Back
// //             </button>
// //             <form className="row g-3" onSubmit={handleSubmit}>
// //               <div className="col-12">
// //                 <label htmlFor="prodName" className="form-label fw-medium">
// //                   Product Name
// //                 </label>
// //                 <input
// //                   type="text"
// //                   className="form-control"
// //                   id="prodName"
// //                   placeholder="Product Name"
// //                   value={product.prodName}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="prodPrice" className="form-label fw-medium">
// //                   Price
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="prodPrice"
// //                   placeholder="Price"
// //                   value={product.prodPrice}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="quantity" className="form-label fw-medium">
// //                   Quantity
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="quantity"
// //                   placeholder="Quantity"
// //                   value={product.quantity}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label
// //                   htmlFor="prodDescription"
// //                   className="form-label fw-medium"
// //                 >
// //                   Product Description
// //                 </label>
// //                 <textarea
// //                   className="form-control"
// //                   id="prodDescription"
// //                   rows="3"
// //                   placeholder="Product Description"
// //                   value={product.prodDescription}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 ></textarea>
// //               </div>
// //               <div className="col-2">
// //                 <label htmlFor="imageColor" className="form-label fw-medium">
// //                   Image Color
// //                 </label>
// //                 <input
// //                   type="color"
// //                   className="form-control"
// //                   id="imageColor"
// //                   placeholder="Image Color"
// //                   value={product.imageColor}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12 mb-3">
// //                 <div className="row">
// //                   <div className="col-4 mb-3">
// //                     <label htmlFor="imagePath" className="form-label fw-medium">
// //                       Images
// //                     </label>
// //                     <input
// //                       type="file"
// //                       className="form-control"
// //                       id="imagePath"
// //                       title="Choose your images"
// //                       multiple
// //                       onChange={handleFileChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="col-12 only-images-name">
// //                     {product.images.map((image, index) => (
// //                       <div key={index} className="uploaded-image">
// //                         <img
// //                           src={
// //                             image instanceof Blob
// //                               ? URL.createObjectURL(image)
// //                               : image
// //                           }
// //                           alt={`Uploaded ${index}`}
// //                         />
// //                         <RxCross2
// //                           onClick={() => handleRemoveImage(index)}
// //                           className="img-cross-icon"
// //                         />
// //                       </div>
// //                     ))}
// //                   </div>
// //                 </div>
// //               </div>
// //               <div className="col-12 d-flex gap-2">
// //                 <label htmlFor="isActive" className="form-label fw-medium">
// //                   Active
// //                 </label>
// //                 <span>
// //                   <input
// //                     type="checkbox"
// //                     id="isActive"
// //                     className="checkbox"
// //                     checked={product.isActive}
// //                     onChange={handleChange}
// //                   />
// //                   <label htmlFor="isActive" className="toggle"></label>
// //                 </span>
// //               </div>
// //               <div className="col-12 d-flex flex-column">
// //                 <label
// //                   htmlFor="prodCategory"
// //                   className="form-label fw-medium m-0"
// //                 >
// //                   Product Category
// //                 </label>
// //                 <select
// //                   className="form-select"
// //                   id="prodCategory"
// //                   value={product.prodCategory}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Category</option>
// //                   <option value="Customized">Customized</option>
// //                   <option value="Non-Customized">Non-Customized</option>
// //                 </select>
// //               </div>
// //               <div className="col-12 mt-5">
// //                 <button
// //                   type="submit"
// //                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto edit-btn"
// //                 >
// //                   Update Product
// //                 </button>
// //               </div>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default EditProduct;
