import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdArrowBackIos } from "react-icons/md";
import "react-quill/dist/quill.snow.css"; // import styles
import ReactQuill from "react-quill";
import { ProductContext } from "../../contexts/ProductContext";

const AddProduct = () => {
  const navigate = useNavigate();
  const { fetchData } = useContext(ProductContext);

  const [product, setProduct] = useState({
    imgName: [], // Initialize as an array
    prodName: "",
    prodCode: "",
    prodCategory: "",
    imageColor: "",
    prodPrice: "",
    quantity: 0,
    prodDescription: "",
    customMessage: "",
    zodiacSign: "",
    isActive: false,
    isB2B: false,
    isLockable: false,
    isZipper: false,
    isCustomize: false,
    images: [],
    prodType: "",
    prodSize: "",
    prodMaterial: "",
  });

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const sizeDataForBox = [
    { id: 1, title: "Small" },
    { id: 2, title: "Medium" },
    { id: 3, title: "Large" },
  ];

  const materialDataForBox = [
    { id: 1, title: "Leather" },
    { id: 2, title: "Fabric" },
    { id: 3, title: "Glass" },
    { id: 4, title: "Velvet" },
  ];

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/home/productTypeList`
        );
        const data = await response.json();
        if (data.status === "Successful") {
          setProductTypes(data.option);
        } else {
          console.error("Failed to fetch product types:", data.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const fetchProductCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prodCategory/search`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              pkProdCategoryId: "",
              prodCategory: "",
              isActive: "",
              isB2B: "",
              isLockable: "",
              isZipper: "",
              isCustomize: "",
              pageSize: 10,
              pageNo: 1,
            }),
          }
        );
        const data = await response.json();
        if (data.status === "Successful") {
          setProductCategories(data.data); // Set the array of categories
        } else {
          console.error("Failed to fetch product categories:", data.message);
        }
      } catch (error) {
        console.error("Error fetching product categories:", error);
      }
    };

    fetchProductTypes();
    fetchProductCategories();
  }, []);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: null, // Clear the error message when the field changes
    }));
    if (type === "checkbox") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [id]: checked,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [id]: value,
      }));
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });
        return compressedFile;
      })
    );
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [...prevProduct.images, ...compressedFiles],
      imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
    }));
  };

  const handleRemoveImage = (index) => {
    setProduct((prevProduct) => {
      const newImages = [...prevProduct.images];
      newImages.splice(index, 1);
      const newImgNames = [...prevProduct.imgName];
      newImgNames.splice(index, 1);
      return { ...prevProduct, images: newImages, imgName: newImgNames };
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!product.prodName) errors.prodName = "Product name is required";
    if (!product.prodCode) errors.prodCode = "Product code is required";
    if (!product.prodPrice) errors.prodPrice = "Price is required";
    if (product.quantity <= 0)
      errors.quantity = "Quantity must be greater than 0";
    if (!product.prodDescription)
      if (!product.prodCategory)
        // errors.prodDescription = "Description is required";
        // if (!product.imageColor) errors.imageColor = "Image color is required";
        errors.prodCategory = "Category is required";
    if (!product.prodType) errors.prodType = "Product type is required";
    if (!product.prodSize) errors.prodSize = "Product size is required";
    if (!product.prodMaterial) errors.prodMaterial = "Product material is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return; // Do not proceed with the API call if there are validation errors
    }

    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        prodName: product.prodName,
        prodCode: product.prodCode,
        prodPrice: product.prodPrice.toString(),
        quantity: product.quantity,
        prodDescription: product.prodDescription,
        customMessage: product.customMessage,
        zodiacSign: product.zodiacSign,
        isActive: product.isActive ? "1" : "0",
        isB2B: product.isB2B ? "1" : "0",
        isLockable: product.isLockable ? "1" : "0",
        isZipper: product.isZipper ? "1" : "0",
        imageColor: product.imageColor,
        imgName: product.imgName.join(","),
        prodCategory: product.prodCategory,
        isCustomize: product.isCustomize ? "1" : "0",
        prodType: product.prodType,
        prodSize: product.prodSize,
        prodMaterial: product.prodMaterial,
      })
    );

    product.images.forEach((file, index) => {
      formData.append(`files`, file, file.name);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Added!", {
        autoClose: 1000,
        position: "bottom-right",
      });
      navigate("/product");

      fetchData();
    } catch (error) {
      if (error.response) {
        console.error(
          "Server responded with error status:",
          error.response.status
        );
        console.error("Response data:", error.response.data);
      } else if (error.request) {
        console.error("No response received from server:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      prodDescription: value,
    }));
  };

  return (
    <section className="add-product-page">
      <div className="our-container px-3">
        <div className="inner-container">
          <div className="add-product-heading mb-4">
            <h2>Add Product</h2>
          </div>
          <div className="add-product-body col-md-8 col-lg-8 col-12 mx-auto">
            <button
              onClick={handleBackClick}
              className="back-btn-per-page mb-5"
            >
              <MdArrowBackIos className="back-icon-per-page" />
              Back
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-12">
                <label htmlFor="prodName" className="form-label fw-medium">
                  Product Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    validationErrors.prodName ? "is-invalid" : ""
                  }`}
                  id="prodName"
                  placeholder="Product Name"
                  value={product.prodName}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {validationErrors.prodName && (
                  <div className="invalid-feedback">
                    {validationErrors.prodName}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="prodCode" className="form-label fw-medium">
                  Product Code
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    validationErrors.prodCode ? "is-invalid" : ""
                  }`}
                  id="prodCode"
                  placeholder="Product Name"
                  value={product.prodCode}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {validationErrors.prodCode && (
                  <div className="invalid-feedback">
                    {validationErrors.prodCode}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="prodPrice" className="form-label fw-medium">
                  Price
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    validationErrors.prodPrice ? "is-invalid" : ""
                  }`}
                  id="prodPrice"
                  placeholder="Price"
                  value={product.prodPrice}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {validationErrors.prodPrice && (
                  <div className="invalid-feedback">
                    {validationErrors.prodPrice}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="quantity" className="form-label fw-medium">
                  Quantity
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    validationErrors.quantity ? "is-invalid" : ""
                  }`}
                  id="quantity"
                  placeholder="Quantity"
                  value={product.quantity}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {validationErrors.quantity && (
                  <div className="invalid-feedback">
                    {validationErrors.quantity}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label
                  htmlFor="prodDescription"
                  className="form-label fw-medium"
                >
                  Product Description
                </label>
                <ReactQuill
                  value={product.prodDescription}
                  onChange={handleDescriptionChange}
                  modules={modules}
                  formats={formats}
                />
                {/* {validationErrors.prodDescription && (
                  <div className="invalid-feedback">
                    {validationErrors.prodDescription}
                  </div>
                )} */}
              </div>
              <div className="col-2">
                <label htmlFor="imageColor" className="form-label fw-medium">
                  Image Color
                </label>
                <input
                  type="color"
                  className={`form-control`}
                  id="imageColor"
                  placeholder="Image Color"
                  value={product.imageColor}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {/* {validationErrors.imageColor && <div className="invalid-feedback">{validationErrors.imageColor}</div>} */}
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-4 mb-3">
                    <label htmlFor="imagePath" className="form-label fw-medium">
                      Images
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imagePath"
                      title="Choose your images"
                      multiple
                      onChange={handleFileChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 only-images-name">
                    {product.images.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Product ${index}`}
                        />
                        <RxCross2
                          onClick={() => handleRemoveImage(index)}
                          className="img-cross-icon"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isActive" className="form-label fw-medium">
                  Active
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isActive"
                    className="form-check"
                    checked={product.isActive}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isB2B" className="form-label fw-medium">
                  B2B
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isB2B"
                    className="form-check"
                    checked={product.isB2B}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isCustomize" className="form-label fw-medium">
                  Customize
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isCustomize"
                    className="form-check"
                    checked={product.isCustomize}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isLockable" className="form-label fw-medium">
                  isLockable
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isLockable"
                    className="form-check"
                    checked={product.isLockable}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className="col-12 d-flex gap-2">
                <label htmlFor="isZipper" className="form-label fw-medium">
                  isZipper
                </label>
                <span>
                  <input
                    type="checkbox"
                    id="isZipper"
                    className="form-check"
                    checked={product.isZipper}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className="col-12 d-flex flex-column">
                <label htmlFor="prodSize" className="form-label fw-medium m-0">
                  Product Size
                </label>
                <select
                  className={`form-select ${
                    validationErrors.prodSize ? "is-invalid" : ""
                  }`}
                  aria-label="Product Size"
                  id="prodSize"
                  value={product.prodSize}
                  onChange={handleChange}
                >
                  <option value="">Select Product Size</option>
                  {sizeDataForBox.map((size) => (
                    <option key={size.id} value={size.title}>
                      {size.title}
                    </option>
                  ))}
                </select>
                {validationErrors.prodSize && (
                  <div className="invalid-feedback">
                    {validationErrors.prodSize}
                  </div>
                )}
              </div>
              <div className="col-12 d-flex flex-column">
                <label htmlFor="prodMaterial" className="form-label fw-medium m-0">
                  Product Material
                </label>
                <select
                  className={`form-select ${
                    validationErrors.prodMaterial ? "is-invalid" : ""
                  }`}
                  aria-label="Product Material"
                  id="prodMaterial"
                  value={product.prodMaterial}
                  onChange={handleChange}
                >
                  <option value="">Select Product Material</option>
                  {materialDataForBox.map((material) => (
                    <option key={material.id} value={material.title}>
                      {material.title}
                    </option>
                  ))}
                </select>
                {validationErrors.prodMaterial && (
                  <div className="invalid-feedback">
                    {validationErrors.prodMaterial}
                  </div>
                )}
              </div>
              <div className="col-12 d-flex flex-column">
                <label
                  htmlFor="prodCategory"
                  className="form-label fw-medium m-0"
                >
                  Product Category
                </label>
                <select
                  className={`form-select ${
                    validationErrors.prodCategory ? "is-invalid" : ""
                  }`}
                  aria-label="Product Category"
                  id="prodCategory"
                  value={product.prodCategory}
                  onChange={handleChange}
                >
                  <option value="">Select Product Category</option>
                  {productCategories.map((category) => (
                    <option
                      key={category.pkProdCategoryId}
                      value={category.productCategory}
                    >
                      {category.productCategory}
                    </option>
                  ))}
                </select>
                {validationErrors.prodCategory && (
                  <div className="invalid-feedback">
                    {validationErrors.prodCategory}
                  </div>
                )}
              </div>
              <div className="col-12 d-flex flex-column">
                <label htmlFor="prodType" className="form-label fw-medium m-0">
                  Product Type
                </label>
                <select
                  className={`form-select ${
                    validationErrors.prodType ? "is-invalid" : ""
                  }`}
                  aria-label="Product Type"
                  id="prodType"
                  value={product.prodType}
                  onChange={handleChange}
                >
                  <option value="">Select Product Type</option>
                  {productTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {validationErrors.prodType && (
                  <div className="invalid-feedback">
                    {validationErrors.prodType}
                  </div>
                )}
              </div>
              <div className="col-12 mt-5">
                <button
                  type="submit"
                  className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto"
                >
                  Add Product
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ align: [] }],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
  "image",
  "align",
];

export default AddProduct;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import imageCompression from "browser-image-compression";
// import { RxCross2 } from "react-icons/rx";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { MdArrowBackIos } from "react-icons/md";
// import 'react-quill/dist/quill.snow.css'; // import styles
// import ReactQuill from "react-quill";

// const AddProduct = () => {
//   const navigate = useNavigate();

//   const [product, setProduct] = useState({
//     imgName: [], // Initialize as an array
//     prodName: "",
//     prodCategory: "",
//     imageColor: "",
//     prodPrice: "",
//     quantity: 0,
//     prodDescription: "",
//     customMessage: "",
//     zodiacSign: "",
//     isActive: false,
//     isCustomize: false,
//     images: [],
//     prodType: "",
//   });

//   const [productTypes, setProductTypes] = useState([]);
//   const [validationErrors, setValidationErrors] = useState({});

//   useEffect(() => {
//     const fetchProductTypes = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/home/productTypeList`);
//         const data = await response.json();
//         if (data.status === "Successful") {
//           setProductTypes(data.option);
//         } else {
//           console.error("Failed to fetch product types:", data.message);
//         }
//       } catch (error) {
//         console.error("Fetch error:", error);
//       }
//     };

//     fetchProductTypes();
//   }, []);

//   const handleChange = (e) => {
//     const { id, value, type, checked } = e.target;
//     setValidationErrors((prevErrors) => ({
//       ...prevErrors,
//       [id]: null, // Clear the error message when the field changes
//     }));
//     if (type === "checkbox") {
//       setProduct((prevProduct) => ({
//         ...prevProduct,
//         [id]: checked,
//       }));
//     } else {
//       setProduct((prevProduct) => ({
//         ...prevProduct,
//         [id]: value,
//       }));
//     }
//   };

//   const handleFileChange = async (e) => {
//     const files = Array.from(e.target.files);
//     const compressedFiles = await Promise.all(
//       files.map(async (file) => {
//         const compressedFile = await imageCompression(file, {
//           maxSizeMB: 1,
//           maxWidthOrHeight: 1920,
//         });
//         return compressedFile;
//       })
//     );
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       images: [...prevProduct.images, ...compressedFiles],
//       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
//     }));
//   };

//   const handleRemoveImage = (index) => {
//     setProduct((prevProduct) => {
//       const newImages = [...prevProduct.images];
//       newImages.splice(index, 1);
//       const newImgNames = [...prevProduct.imgName];
//       newImgNames.splice(index, 1);
//       return { ...prevProduct, images: newImages, imgName: newImgNames };
//     });
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!product.prodName) errors.prodName = "Product name is required";
//     if (!product.prodPrice) errors.prodPrice = "Price is required";
//     if (product.quantity <= 0) errors.quantity = "Quantity must be greater than 0";
//     if (!product.prodDescription) errors.prodDescription = "Description is required";
//     if (!product.imageColor) errors.imageColor = "Image color is required";
//     if (!product.prodCategory) errors.prodCategory = "Category is required";
//     if (!product.prodType) errors.prodType = "Product type is required";
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setValidationErrors(errors);
//       return; // Do not proceed with the API call if there are validation errors
//     }

//     const formData = new FormData();
//     formData.append(
//       "data",
//       JSON.stringify({
//         prodName: product.prodName,
//         prodPrice: product.prodPrice.toString(),
//         quantity: product.quantity,
//         prodDescription: product.prodDescription,
//         customMessage: product.customMessage,
//         zodiacSign: product.zodiacSign,
//         isActive: product.isActive ? "1" : "0",
//         imageColor: product.imageColor,
//         imgName: product.imgName.join(","),
//         prodCategory: product.prodCategory,
//         isCustomize: product.isCustomize ? "1" : "0",
//         prodType: product.prodType,
//       })
//     );

//     product.images.forEach((file, index) => {
//       formData.append(`files`, file, file.name);
//     });

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/product/add`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       toast.success("Added!",{
//         autoClose: 1000,
//         position: 'bottom-right'
//       });
//       navigate("/product");
//     } catch (error) {
//       if (error.response) {
//         console.error(
//           "Server responded with error status:",
//           error.response.status
//         );
//         console.error("Response data:", error.response.data);
//       } else if (error.request) {
//         console.error("No response received from server:", error.request);
//       } else {
//         console.error("Error setting up the request:", error.message);
//       }
//     }
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   const handleDescriptionChange = (value) => {
//     setProduct((prevProduct) => ({
//       ...prevProduct,
//       prodDescription: value,
//     }));
//   };

//   return (
//     <section className="add-product-page">
//       <div className="our-container px-3">
//         <div className="inner-container">
//           <div className="add-product-heading mb-4">
//             <h2>Add Product</h2>
//           </div>
//           <div className="add-product-body col-md-8 col-lg-8 col-12 mx-auto">
//             <button
//               onClick={handleBackClick}
//               className="back-btn-per-page mb-5"
//             >
//               <MdArrowBackIos className="back-icon-per-page" />
//               Back
//             </button>
//             <form className="row g-3" onSubmit={handleSubmit}>
//               <div className="col-12">
//                 <label htmlFor="prodName" className="form-label fw-medium">
//                   Product Name
//                 </label>
//                 <input
//                   type="text"
//                   className={`form-control ${validationErrors.prodName ? 'is-invalid' : ''}`}
//                   id="prodName"
//                   placeholder="Product Name"
//                   value={product.prodName}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//                 {validationErrors.prodName && <div className="invalid-feedback">{validationErrors.prodName}</div>}
//               </div>
//               <div className="col-12">
//                 <label htmlFor="prodPrice" className="form-label fw-medium">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   className={`form-control ${validationErrors.prodPrice ? 'is-invalid' : ''}`}
//                   id="prodPrice"
//                   placeholder="Price"
//                   value={product.prodPrice}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//                 {validationErrors.prodPrice && <div className="invalid-feedback">{validationErrors.prodPrice}</div>}
//               </div>
//               <div className="col-12">
//                 <label htmlFor="quantity" className="form-label fw-medium">
//                   Quantity
//                 </label>
//                 <input
//                   type="number"
//                   className={`form-control ${validationErrors.quantity ? 'is-invalid' : ''}`}
//                   id="quantity"
//                   placeholder="Quantity"
//                   value={product.quantity}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//                 {validationErrors.quantity && <div className="invalid-feedback">{validationErrors.quantity}</div>}
//               </div>
//               <div className="col-12">
//                 <label
//                   htmlFor="prodDescription"
//                   className="form-label fw-medium"
//                 >
//                   Product Description
//                 </label>
//                 <ReactQuill
//                   value={product.prodDescription}
//                   onChange={handleDescriptionChange}
//                   modules={modules}
//                   formats={formats}
//                 />
//                 {validationErrors.prodDescription && (
//                   <div className="invalid-feedback">{validationErrors.prodDescription}</div>
//                 )}
//               </div>
//               <div className="col-2">
//                 <label htmlFor="imageColor" className="form-label fw-medium">
//                   Image Color
//                 </label>
//                 <input
//                   type="color"
//                   className={`form-control`}
//                   id="imageColor"
//                   placeholder="Image Color"
//                   value={product.imageColor}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//                 {/* {validationErrors.imageColor && <div className="invalid-feedback">{validationErrors.imageColor}</div>} */}
//               </div>
//               <div className="col-12 mb-3">
//                 <div className="row">
//                   <div className="col-4 mb-3">
//                     <label htmlFor="imagePath" className="form-label fw-medium">
//                       Images
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       id="imagePath"
//                       title="Choose your images"
//                       multiple
//                       onChange={handleFileChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="col-12 only-images-name">
//                     {product.images.map((image, index) => (
//                       <div key={index} className="uploaded-image">
//                         <img
//                           src={URL.createObjectURL(image)}
//                           alt={`Product ${index}`}
//                         />
//                         <RxCross2
//                           onClick={() => handleRemoveImage(index)}
//                           className="img-cross-icon"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isActive" className="form-label fw-medium">
//                   Active
//                 </label>
//                 <span>
//                   <input
//                     type="checkbox"
//                     id="isActive"
//                     className="form-check"
//                     checked={product.isActive}
//                     onChange={handleChange}
//                     autoComplete="off"
//                   />
//                 </span>
//               </div>
//               <div className="col-12 d-flex gap-2">
//                 <label htmlFor="isCustomize" className="form-label fw-medium">
//                   Customize
//                 </label>
//                 <span>
//                   <input
//                     type="checkbox"
//                     id="isCustomize"
//                     className="form-check"
//                     checked={product.isCustomize}
//                     onChange={handleChange}
//                     autoComplete="off"
//                   />
//                 </span>
//               </div>
//               <div className="col-12 d-flex flex-column">
//                 <label
//                   htmlFor="prodCategory"
//                   className="form-label fw-medium m-0"
//                 >
//                   Product Category
//                 </label>
//                 <input
//                   type="text"
//                   className={`form-control ${validationErrors.prodCategory ? 'is-invalid' : ''}`}
//                   id="prodCategory"
//                   placeholder="Product Category"
//                   value={product.prodCategory}
//                   onChange={handleChange}
//                   autoComplete="off"
//                 />
//                 {validationErrors.prodCategory && <div className="invalid-feedback">{validationErrors.prodCategory}</div>}
//               </div>
//               <div className="col-12 d-flex flex-column">
//                 <label
//                   htmlFor="prodType"
//                   className="form-label fw-medium m-0"
//                 >
//                   Product Type
//                 </label>
//                 <select
//                   className={`form-select ${validationErrors.prodType ? 'is-invalid' : ''}`}
//                   aria-label="Product Type"
//                   id="prodType"
//                   value={product.prodType}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Product Type</option>
//                   {productTypes.map((type, index) => (
//                     <option key={index} value={type}>
//                       {type}
//                     </option>
//                   ))}
//                 </select>
//                 {validationErrors.prodType && <div className="invalid-feedback">{validationErrors.prodType}</div>}
//               </div>
//               <div className="col-12 mt-5">
//                 <button
//                   type="submit"
//                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto"
//                 >
//                   Add Product
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// const modules = {
//   toolbar: [
//     [{ 'header': '1' }, { 'header': '2' }],
//     ['bold', 'italic', 'underline'],
//     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//     ['link', 'image'],
//     [{ 'align': [] }],
//   ],
// };

// const formats = [
//   'header',
//   'bold',
//   'italic',
//   'underline',
//   'list',
//   'bullet',
//   'link',
//   'image',
//   'align',
// ];

// export default AddProduct;

// // import React, { useEffect, useState } from "react";
// // import axios from "axios";
// // import imageCompression from "browser-image-compression";
// // import { RxCross2 } from "react-icons/rx";
// // import { useNavigate } from "react-router-dom";
// // import { toast } from "react-toastify";
// // import { MdArrowBackIos } from "react-icons/md";

// // const AddProduct = () => {
// //   const navigate = useNavigate();

// //   const [product, setProduct] = useState({
// //     imgName: [],
// //     prodName: "",
// //     prodCategory: "",
// //     imageColor: "",
// //     prodPrice: "",
// //     quantity: 0,
// //     prodDescription: "",
// //     customMessage: "",
// //     zodiacSign: "",
// //     isActive: false,
// //     isCustomize: false,
// //     images: [],
// //     prodType: "", // Added prodType
// //   });

// //   const [productTypes, setProductTypes] = useState([]);
// //   const [categories, setCategories] = useState([]); // Added for product categories

// //   useEffect(() => {
// //     const fetchProductData = async () => {
// //       try {
// //         const [typeResponse, categoryResponse] = await Promise.all([
// //           fetch(`${process.env.REACT_APP_API_URL}/home/productTypeList`),
// //           fetch(`${process.env.REACT_APP_API_URL}/home/productCategoryList`),
// //         ]);

// //         const typeData = await typeResponse.json();
// //         if (typeData.status === "Successful") {
// //           setProductTypes(typeData.option);
// //         } else {
// //           console.error("Failed to fetch product types:", typeData.message);
// //         }

// //         const categoryData = await categoryResponse.json();
// //         if (categoryData.status === "Successful") {
// //           setCategories(categoryData.option);
// //         } else {
// //           console.error("Failed to fetch product categories:", categoryData.message);
// //         }
// //       } catch (error) {
// //         console.error("Fetch error:", error);
// //       }
// //     };

// //     fetchProductData();
// //   }, []);

// //   const handleChange = (e) => {
// //     const { id, value, type, checked } = e.target;
// //     if (type === "checkbox") {
// //       setProduct((prevProduct) => ({
// //         ...prevProduct,
// //         [id]: checked,
// //       }));
// //     } else {
// //       setProduct((prevProduct) => ({
// //         ...prevProduct,
// //         [id]: value,
// //       }));
// //     }
// //   };

// //   const handleFileChange = async (e) => {
// //     const files = Array.from(e.target.files);
// //     const compressedFiles = await Promise.all(
// //       files.map(async (file) => {
// //         const compressedFile = await imageCompression(file, {
// //           maxSizeMB: 1,
// //           maxWidthOrHeight: 1920,
// //         });
// //         return compressedFile;
// //       })
// //     );
// //     setProduct((prevProduct) => ({
// //       ...prevProduct,
// //       images: [...prevProduct.images, ...compressedFiles],
// //       imgName: [...prevProduct.imgName, ...files.map((file) => file.name)],
// //     }));
// //   };

// //   const handleRemoveImage = (index) => {
// //     setProduct((prevProduct) => {
// //       const newImages = [...prevProduct.images];
// //       newImages.splice(index, 1);
// //       const newImgNames = [...prevProduct.imgName];
// //       newImgNames.splice(index, 1);
// //       return { ...prevProduct, images: newImages, imgName: newImgNames };
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     const formData = new FormData();
// //     formData.append(
// //       "data",
// //       JSON.stringify({
// //         prodName: product.prodName,
// //         prodPrice: product.prodPrice.toString(),
// //         quantity: product.quantity,
// //         prodDescription: product.prodDescription,
// //         customMessage: product.customMessage,
// //         zodiacSign: product.zodiacSign,
// //         isActive: product.isActive ? "1" : "0",
// //         imageColor: product.imageColor,
// //         imgName: product.imgName.join(","),
// //         prodCategory: product.prodCategory,
// //         isCustomize: product.isCustomize ? "1" : "0",
// //         prodType: product.prodType, // Added prodType
// //       })
// //     );

// //     product.images.forEach((file, index) => {
// //       formData.append(`files`, file, file.name);
// //     });

// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/product/add`,
// //         formData,
// //         {
// //           headers: {
// //             "Content-Type": "multipart/form-data",
// //             Authorization: `Bearer ${localStorage.getItem("token")}`,
// //           },
// //         }
// //       );
// //       toast.success("Added!");
// //       navigate("/product");
// //     } catch (error) {
// //       if (error.response) {
// //         console.error(
// //           "Server responded with error status:",
// //           error.response.status
// //         );
// //         console.error("Response data:", error.response.data);
// //       } else if (error.request) {
// //         console.error("No response received from server:", error.request);
// //       } else {
// //         console.error("Error setting up the request:", error.message);
// //       }
// //     }
// //   };

// //   const handleBackClick = () => {
// //     navigate(-1);
// //   };

// //   return (
// //     <section className="add-product-page">
// //       <div className="our-container px-3">
// //         <div className="inner-container">
// //           <div className="add-product-heading mb-4">
// //             <h2>Add Product</h2>
// //           </div>
// //           <div className="add-product-body col-md-8 col-lg-8 col-12 mx-auto">
// //             <button
// //               onClick={handleBackClick}
// //               className="back-btn-per-page mb-5"
// //             >
// //               <MdArrowBackIos className="back-icon-per-page" />
// //               Back
// //             </button>
// //             <form className="row g-3" onSubmit={handleSubmit}>
// //               <div className="col-12">
// //                 <label htmlFor="prodName" className="form-label fw-medium">
// //                   Product Name
// //                 </label>
// //                 <input
// //                   type="text"
// //                   className="form-control"
// //                   id="prodName"
// //                   placeholder="Product Name"
// //                   value={product.prodName}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="prodPrice" className="form-label fw-medium">
// //                   Price
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="prodPrice"
// //                   placeholder="Price"
// //                   value={product.prodPrice}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label htmlFor="quantity" className="form-label fw-medium">
// //                   Quantity
// //                 </label>
// //                 <input
// //                   type="number"
// //                   className="form-control"
// //                   id="quantity"
// //                   placeholder="Quantity"
// //                   value={product.quantity}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12">
// //                 <label
// //                   htmlFor="prodDescription"
// //                   className="form-label fw-medium"
// //                 >
// //                   Product Description
// //                 </label>
// //                 <textarea
// //                   className="form-control"
// //                   id="prodDescription"
// //                   rows="3"
// //                   placeholder="Product Description"
// //                   value={product.prodDescription}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 ></textarea>
// //               </div>
// //               <div className="col-2">
// //                 <label htmlFor="imageColor" className="form-label fw-medium">
// //                   Image Color
// //                 </label>
// //                 <input
// //                   type="color"
// //                   className="form-control"
// //                   id="imageColor"
// //                   placeholder="Image Color"
// //                   value={product.imageColor}
// //                   onChange={handleChange}
// //                   autoComplete="off"
// //                 />
// //               </div>
// //               <div className="col-12 mb-3">
// //                 <div className="row">
// //                   <div className="col-4 mb-3">
// //                     <label htmlFor="imagePath" className="form-label fw-medium">
// //                       Images
// //                     </label>
// //                     <input
// //                       type="file"
// //                       className="form-control"
// //                       id="imagePath"
// //                       title="Choose your images"
// //                       multiple
// //                       onChange={handleFileChange}
// //                       autoComplete="off"
// //                     />
// //                   </div>
// //                   <div className="col-12 only-images-name">
// //                     {product.images.map((image, index) => (
// //                       <div key={index} className="uploaded-image">
// //                         <img
// //                           src={URL.createObjectURL(image)}
// //                           alt={`Product ${index}`}
// //                         />
// //                         <RxCross2
// //                           onClick={() => handleRemoveImage(index)}
// //                           className="img-cross-icon"
// //                         />
// //                       </div>
// //                     ))}
// //                   </div>
// //                 </div>
// //               </div>
// //               <div className="col-12 d-flex gap-2">
// //                 <label htmlFor="isActive" className="form-label fw-medium">
// //                   Active
// //                 </label>
// //                 <span>
// //                   <input
// //                     type="checkbox"
// //                     id="isActive"
// //                     className="checkbox"
// //                     checked={product.isActive}
// //                     onChange={handleChange}
// //                     autoComplete="off"
// //                   />
// //                   <label htmlFor="isActive" className="toggle"></label>
// //                 </span>
// //               </div>
// //               <div className="col-12 d-flex gap-2">
// //                 <label htmlFor="isCustomize" className="form-label fw-medium">
// //                   Customize
// //                 </label>
// //                 <span>
// //                   <input
// //                     type="checkbox"
// //                     id="isCustomize"
// //                     className="checkbox-customize"
// //                     checked={product.isCustomize}
// //                     onChange={handleChange}
// //                     autoComplete="off"
// //                   />
// //                 </span>
// //               </div>
// //               <div className="col-12 d-flex flex-column">
// //                 <label
// //                   htmlFor="prodCategory"
// //                   className="form-label fw-medium m-0"
// //                 >
// //                   Product Category
// //                 </label>
// //                 <select
// //                   className="form-select"
// //                   aria-label="Product Category"
// //                   id="prodCategory"
// //                   value={product.prodCategory}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Product Category</option>
// //                   {categories.map((category, index) => (
// //                     <option key={index} value={category}>
// //                       {category}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </div>
// //               <div className="col-12 d-flex flex-column">
// //                 <label
// //                   htmlFor="prodType"
// //                   className="form-label fw-medium m-0"
// //                 >
// //                   Product Type
// //                 </label>
// //                 <select
// //                   className="form-select"
// //                   aria-label="Product Type"
// //                   id="prodType"
// //                   value={product.prodType}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Product Type</option>
// //                   {productTypes.map((type, index) => (
// //                     <option key={index} value={type}>
// //                       {type}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </div>
// //               <div className="col-12 mt-5">
// //                 <button
// //                   type="submit"
// //                   className="btn btn-primary col-12 d-flex align-items-center justify-content-center mx-auto"
// //                 >
// //                   Add Product
// //                 </button>
// //               </div>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default AddProduct;
